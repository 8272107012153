import { createGlobalStyle } from 'styled-components';
import './flaticon.css';

export const ResetCSS = createGlobalStyle`
  ::selection {
    background: #333333;
    color: #ffffff;
  }

  html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  *:focus {
    outline: none;
  }

  html,
  html a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  dl,
  th,
  dt,
  input,
  textarea,
  span,
  div {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  }

  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: none;
  }

  .reuseModalHolder {
    padding: 0 !important;
    &.demo_switcher_modal {
      border: 0 !important;
      background-color: rgba(16, 30, 77, 0.9) !important;
      .innerRndComponent {
        border-radius: 8px !important;
      }
    }
  }

  button.modalCloseBtn {
    position: fixed !important;
    z-index: 999991 !important;
    background-color: transparent !important;
    top: 10px !important;
    right: 10px !important;
    min-width: 34px !important;
    min-height: 34px !important;
    padding: 0 !important;
    span.btn-icon {
      font-size: 22px !important;
      transform: rotate(45deg) !important;
    }

    &.alt {
      border-radius: 50% !important;
      z-index: 999999 !important;
      padding: 0 !important;
      transition: all 0.3s ease !important;
      top: 25px !important;
      right: 30px !important;
      min-width: 40px !important;
      min-height: 40px !important;

      span.btn-icon {
        font-size: 20px !important;
      }

      &:hover {
        opacity: 0.88 !important;
      }
    }
  }

  #article-content {
    width: 800px;
    margin-bottom: 60px;

    a {
      color: #18B8E8;
    }

  @media only screen and (max-width: 991px) {
    padding-left: 50px;
    h2 {
      font-size: 30px;
    }
  }

  @media only screen and (max-width: 767px) {
    padding-left: 0;
    width: 100%;
    margin-bottom: 60px;

    h2 {
      font-size: 22px;
    }
  }

    h2 {
      color: #3D636C;
      font-weight: 400;
      letter-spacing: -0.025em;
      line-height: 1.3;
      font-size: 32px;
  
      @media only screen and (min-width: 1441px) {
        margin-bottom: 25px;
      }
  
      @media only screen and (min-width: 1440px) {
        margin-bottom: 20px;
      }
  
      @media only screen and (min-width: 767px) {
        margin-bottom: 15px;
      }
    }

    h3 {
      color: #2CC36B;
      font-size: 28px;
      font-weight: normal;
    }
  
    p, ul, li {
      margin-bottom: 30px;   
      font-size: 16px;
      font-weight: 400;
      color: #3D636C;
      line-height: 1.85;
      margin-bottom: 0;
      letter-spacing: 0;
    }

    li {
      padding-left: 40px;
    }
  }
`;
